.required:after {
    content:" *";
    color: red;
  }

  .samerow {
    display: grid!important;
    grid-template-columns: 55% 45%;
}

.black {
  color: black!important;
}

.mb-10 {
  margin-bottom: 10vh;
}

