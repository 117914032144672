.productList {
    flex: 4;
}

.productListItem {
    display: flex;
    align-items: center;
}

.productListImg {
    width: 32px;
    height: 32px;
    border-radius: 50%;
    object-fit: cover;
    margin-right: 10px;
}

.productListEdit {
    border: none;
    padding: 5px 10px;
    background-color: #014451;
    color: white;
    cursor: pointer;
    margin-right: 20px;
    min-width: 5rem;
    border-radius: 20px;
}

.productListDelete {
    color: red;
    cursor: pointer;
}

.addContainer {
    display: flex;
    justify-content: flex-end;
    margin: 1rem 0;
}

.capitalize {
    text-transform: capitalize;
}

.overlayContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    color: white;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    top: 0;
    background: rgba(0, 0, 0, 0.6);
    visibility: visible;
}

.center {
    position: absolute;
    background: white;
    color: black;
    padding: 0 20px;
    width: 80vh;
    text-align: center;
}

.updateOrderItem {
    width: 100%;
    padding: 10px 0;
    display: flex;
    flex-direction: column;
    margin-bottom: 10px;
    justify-content: center;
}

.updateOrder {
    margin: 40px 50px;
}

.productContainer {
    display: grid;
    padding: 10px 0;
    grid-auto-flow: column;
    overflow-x: auto;
}

.productImg {
    height: 20vh;
    width: 20vh;
}

.pointer {
    cursor: pointer;
}

.buttonContainer {
    display: grid;
    grid-template-columns: auto auto;
    margin: 20px 0;
    grid-gap: 40px;
}

.close {
    cursor: pointer;
    position: absolute;
    top: 1rem;
    right: 2rem;
}

.cancel {
    background: red;
    color: white;
    padding: 10px;
}

.confirm {
    background: green;
    color: white;
    padding: 10px;
}

.actionContainer {
    padding: 30px 10px;
}

.sameRowItem {
    width: 15vw;
}

.marginRight {
    margin-right: 30px;
}

.mb-2 {
    margin-bottom: 1rem;
}

.spaceBetween {
    justify-content: space-around;
}

.marginRightSmall {
    margin-right: 25px;
}

.samerow {
    display: flex !important;
}

.flex {
    display: flex;
    justify-content: center;
}

@media (max-width: 480px) {
    .samerow {
        display: grid !important;
        grid-template-columns: 100%;
    }

    .sameRowItem {
        width: 50vw;
        margin-bottom: 10px;
    }
}

@media (max-width: 1000px) {
    .samerow {
        margin: 20px 0;
    }
}
