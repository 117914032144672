.home{
    flex: 4 1;
}

.container{
    display: flex;
}

.homeWidgets{
    display: flex;
    margin: 20px;
}