.sidebar {
  flex: 1;
  height: 100%;
  position: sticky;
  top: 50px;
  background-color: rgb(251, 251, 255);
}

.sidebarWrapper {
  min-height: 100vh;
  padding: 20px;
  color: #555;

  width: 14vw;
}

.sidebarMenu {
  margin-bottom: 10px;
}

.sidebarTitle {
  font-size: 13px;
  color: rgb(187, 186, 186);
}

.sidebarList {
  list-style: none;
  padding: 5px;
}

.sidebarListItem {
  padding: 5px;
  cursor: pointer;
  display: flex;
  align-items: center;
  border-radius: 10px;
}

.sidebarListItem.active,
.sidebarListItem:hover {
  background-color: rgb(240, 240, 255);
}

.sidebarIcon{
    margin-right: 5px;
    font-size: 20px !important;
}


@media (max-width:1000px)  { 
  .sidebarWrapper {
    width: 25vw;
  }
}

@media (max-width:480px)  { 
  .sidebarWrapper {
    width: 100%;
  }
}
